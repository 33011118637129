import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueMeta from 'vue-meta';


// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome' 
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowUp, faPhone, faEnvelope, faLanguage, faUser, faLinesLeaning, faCircle, faFileContract, faLocationDot, faSun, faMoon, faTerminal } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

library.add(faArrowUp, faPhone, faEnvelope, faLanguage, faUser, faLinesLeaning, faCircle, faFileContract, faLocationDot, faSun, faMoon, faTerminal, faWhatsapp, faGithub, faLinkedin );
Vue.component('font-awesome-icon', FontAwesomeIcon);

require('@/assets/main.scss');

Vue.config.productionTip = false

new Vue({
  router,
  store,
  VueMeta,
  render: function (h) { return h(App) }
}).$mount('#app')
