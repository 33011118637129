import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: function () {
      return import(/* webpackChunkName: "home" */ '@/views/HomeView.vue')
    }
  },
  // Catch all unmatched routes
  {
    path: '/:pathMatch(.*)*', // Use this syntax for Vue Router 4
    name: 'NotFound',
    component: function () {
      return import(/* webpackChunkName: "notfound" */ '@/views/NotFound.vue')
    },
    meta: { 
      hideNavbarAndFooter: true 
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0)
  },
})

export default router
